<template>
  <div>
    <v-app-bar app fixed dark color="primary">
      <v-toolbar-title>
        <v-img
          class="pl-0"
          width="100px"
          :src="require('@/assets/images/logo-menu.png')"
        />
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <div>
        <p class="ma-0">
          Bem-vindo, <b>{{ customer.name }}</b>
        </p>
        <p class="ma-0 caption">
          <small>{{ customer.customer.companyName }}</small>
        </p>
      </div>

      <v-btn icon small class="ml-2" title="Sair" color="red" @click="logout">
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>

      <template v-slot:extension>
        <v-tabs v-model="tab" centered>
          <v-tabs-slider color="warning"></v-tabs-slider>
          <v-tab key="reports">
            Relatórios
          </v-tab>
          <v-tab key="complementary-report">
            Relatório Complementar
          </v-tab>
          <v-tab key="tasks">
            Tarefas
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-main class="blue-grey lighten-5" style="min-height: 100vh">
      <v-container fluid>
        <v-row justify="center">
          <v-col md="6">
            <v-tabs-items v-model="tab" class="transparent">
              <v-tab-item key="reports" class="reports" :transition="null">
                <tab-item-reports />
              </v-tab-item>
              <v-tab-item
                key="complementary-report"
                class="complementary-report"
                :transition="null"
              >
                <tab-item-complementary-report />
              </v-tab-item>
              <v-tab-item key="tasks" class="tasks" :transition="null">
                <tab-item-tasks />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    TabItemReports: () =>
      import('@/views/pages-customer/dashboard/tabs/Reports'),
    TabItemComplementaryReport: () =>
      import('@/views/pages-customer/dashboard/tabs/ComplementaryReport'),
    TabItemTasks: () => import('@/views/pages-customer/dashboard/tabs/Tasks'),
  },

  data: () => ({
    tab: 'reports',
  }),

  computed: {
    ...mapState('customer', ['customer']),
  },

  methods: {
    ...mapActions({
      logoutAction: 'logoutCustomer',
    }),
    logout() {
      this.logoutAction()
      this.$router.push('/acesso-cliente/login')
    },
  },
}
</script>
